@use 'sass:color';
@use '../base/defaults';

.loader-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-container ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.loader-container ul li {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.loader {
  width: defaults.$size-3xl * 3;
  height: defaults.$size-3xs;
  background-color: defaults.$shadow-grey;
  border-radius: 20px;
  overflow: hidden;
}

.child {
  width: 60px;
  height: defaults.$size-3xs;
  background-color: defaults.$colour-gyaan;
  border-radius: 20px;
  z-index: 0;
  margin-left: -60px;
  animation: go 1s 0s infinite;
}

@keyframes go {
  from {
    margin-left: -(defaults.$size-3xl) * 3;
    width: defaults.$size-3xl;
  }
  to {
    width: defaults.$size-m;
    margin-left: defaults.$size-3xl * 3;
  }
}

.text {
  width: defaults.$size-2xl * 2;
  background-color: transparent;
  margin-top: 20px;
  text-align: left;
  font-size: defaults.$font-size-2xl;
}

.text::before {
  content: 'Loading';
  color: color.adjust(defaults.$colour-gyaan, $lightness: 15%);
  animation: text 1.5s 0s infinite;
}

@keyframes text {
  0% {
    content: 'Loading';
  }

  30% {
    content: 'Loading.';
  }

  60% {
    content: 'Loading..';
  }

  100% {
    content: 'Loading...';
  }
}
