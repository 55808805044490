@use '../base/defaults';

.h1__skeleton {
  flex-grow: 1;
}

.h2__skeleton {
  @extend h2 !optional;
  // background-color: lighten($colour-gyaan, 5);
}

.h3__skeleton {
  @extend h3 !optional;
  color: defaults.$light-grey;
  opacity: 0.5;
  cursor: progress;
  height: defaults.$size-xl;
  width: defaults.$size-3xl * 3;
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(defaults.$light-grey, defaults.$light-grey);
  background-repeat: no-repeat;
  background-size:
    defaults.$size-3xl * 6 250px,
    defaults.$size-3xl * 6 180px;
  background-position:
    -(defaults.$size-3xl) * 6 0,
    0 0;
  animation: loading 1.5s infinite;
}

.h4__skeleton {
  @extend h4 !optional;
  color: defaults.$light-grey;
  opacity: 0.5;
  cursor: progress;
  height: defaults.$size-l;
  width: defaults.$size-3xl * 3;
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(defaults.$light-grey, defaults.$light-grey);
  background-repeat: no-repeat;
  background-size:
    defaults.$size-3xl * 6 250px,
    defaults.$size-3xl * 6 180px;
  background-position:
    -(defaults.$size-3xl) * 6 0,
    0 0;
  animation: loading 1.5s infinite;
}

.text__skeleton {
  @extend p !optional;
  color: defaults.$light-grey;
  opacity: 0.5;
  height: defaults.$size-l;
  width: defaults.$size-3xl * 2;
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(defaults.$light-grey, defaults.$light-grey);
  background-repeat: no-repeat;
  background-size:
    defaults.$size-3xl * 6 250px,
    defaults.$size-3xl * 6 180px;
  background-position:
    -(defaults.$size-3xl) * 6 0,
    0 0;
  animation: loading 1.5s infinite;
}

.btn__skeleton--primary {
  min-width: defaults.$size-xl * 3;
  height: defaults.$size-2xl;
  opacity: 0.5;
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(defaults.$light-grey, defaults.$light-grey);
  background-repeat: no-repeat;
  background-size:
    defaults.$size-3xl * 6 250px,
    defaults.$size-3xl * 6 180px;
  background-position:
    -(defaults.$size-3xl) * 6 0,
    0 0;
  animation: loading 1.5s infinite;
}

.btn__skeleton--secondary {
  min-width: defaults.$size-xl * 3;
  height: defaults.$size-2xl;
  opacity: 0.5;
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(defaults.$light-grey, defaults.$light-grey);
  background-repeat: no-repeat;
  background-size:
    defaults.$size-3xl * 6 250px,
    defaults.$size-3xl * 6 180px;
  background-position:
    -(defaults.$size-3xl) * 6 0,
    0 0;
  animation: loading 1.5s infinite;
  &:disabled {
    background: linear-gradient(0.25turn, transparent, #fff, transparent),
      linear-gradient(defaults.$light-grey, defaults.$light-grey);
  }
}

@keyframes loading {
  to {
    background-position:
      315px 0,
      0 0;
  }
}

@media only screen and (max-width: defaults.$breakpoint-md) {
  .btn__skeleton--primary {
    min-width: defaults.$size-l * 3;
    height: defaults.$size-xl;
  }

  .btn__skeleton--secondary {
    min-width: defaults.$size-xl * 2;
    height: defaults.$size-xl;
  }
}
