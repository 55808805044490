@use 'sass:color';
@use '../base/defaults';

.home-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: defaults.$size-l;
}

.welcome-section {
  text-align: center;
  margin-bottom: defaults.$size-xl;
  padding: defaults.$size-xl 0;
  background: linear-gradient(
    135deg,
    defaults.$colour-gyaan,
    color.adjust(defaults.$colour-gyaan, $lightness: -15%)
  );
  border-radius: 12px;
  color: white;

  h1 {
    font-size: defaults.$font-size-3xl;
    margin-bottom: defaults.$size-s;
  }

  p {
    font-size: defaults.$font-size-xl;
    opacity: 0.9;
  }
}

.quick-actions {
  margin-bottom: defaults.$size-xl;

  h2 {
    margin-bottom: defaults.$size-l;
    color: defaults.$grey;
  }
}

.quick-actions-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: defaults.$size-l;
}

.quick-action-card {
  background: white;
  padding: defaults.$size-l;
  border-radius: 12px;
  text-decoration: none;
  color: defaults.$grey;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

  &:hover {
    cursor: pointer;
    transform: translateY(-4px);
    border-color: defaults.$colour-gyaan;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .quick-action-icon {
    margin-bottom: defaults.$size-m;
    color: defaults.$colour-gyaan;

    svg {
      font-size: defaults.$size-xl;
      width: defaults.$size-xl;
      height: defaults.$size-xl;
    }
  }

  h3 {
    color: defaults.$colour-gyaan;
    margin-bottom: defaults.$size-s;
    font-size: defaults.$font-size-2xl;
  }

  p {
    opacity: 0.8;
    font-size: defaults.$font-size-l;
    line-height: 1.5;
  }
}

.trending-section {
  margin-bottom: defaults.$size-xl;

  h2 {
    margin-bottom: defaults.$size-l;
    color: defaults.$grey;
  }
}

.trending-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: defaults.$size-l;
}

.trending-card.card-item {
  transition: all 0.3s ease;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin: 0;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
}

.trending-card-content {
  padding: defaults.$size-m;

  h3 {
    color: defaults.$colour-gyaan;
    margin-bottom: defaults.$size-xs;
    font-size: defaults.$font-size-xl;
  }

  p {
    color: defaults.$grey;
    opacity: 0.8;
    margin-bottom: defaults.$size-s;
    font-size: defaults.$font-size-m;
  }
}

.trending-card-stats {
  display: flex;
  align-items: center;
  color: defaults.$grey;
  font-size: defaults.$font-size-m;
  margin-top: defaults.$size-m;
  padding-top: defaults.$size-m;
  border-top: 1px solid rgba(0, 0, 0, 0.05);

  span {
    display: flex;
    align-items: center;
    gap: defaults.$size-xs;
  }
}

.recent-activity {
  h2 {
    margin-bottom: defaults.$size-l;
    color: defaults.$grey;
  }
}

.activity-card.card-item {
  padding: defaults.$size-m;
  color: defaults.$grey;
  text-align: center;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin: 0;
}

@media only screen and (max-width: defaults.$breakpoint-lg) {
  .home-container {
    padding: defaults.$size-m;
    max-width: 900px;
  }

  .welcome-section {
    padding: defaults.$size-l 0;
    margin-bottom: defaults.$size-l;

    h1 {
      font-size: defaults.$font-size-2xl;
    }

    p {
      font-size: defaults.$font-size-l;
    }
  }

  .quick-actions-grid,
  .trending-grid {
    grid-template-columns: 1fr;
    gap: defaults.$size-m;
  }

  .quick-action-card {
    padding: defaults.$size-m;

    h3 {
      color: defaults.$colour-gyaan;
      margin-bottom: defaults.$size-s;
      font-size: defaults.$font-size-xl;
    }

    .quick-action-icon {
      margin-bottom: defaults.$size-m;
      color: defaults.$colour-gyaan;

      svg {
        font-size: defaults.$size-l;
        width: defaults.$size-l;
        height: defaults.$size-l;
      }
    }

    p {
      opacity: 0.8;
      font-size: defaults.$font-size-m;
      line-height: 1.5;
    }
  }
}

@media only screen and (max-width: defaults.$breakpoint-md) {
  .home-container {
    padding: defaults.$size-s;
  }

  .quick-actions-grid,
  .trending-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: defaults.$size-l;
  }

  .quick-action-card {
    padding: defaults.$size-s;

    .quick-action-icon svg {
      font-size: defaults.$size-l;
      width: defaults.$size-l;
      height: defaults.$size-l;
    }

    h3 {
      font-size: defaults.$font-size-2xl;
    }

    p {
      opacity: 0.8;
      font-size: defaults.$font-size-l;
      line-height: 1.5;
    }
  }
}

@media only screen and (max-width: defaults.$breakpoint-sm) {
  .home-container {
    padding: defaults.$size-xs;
  }

  .welcome-section {
    padding: defaults.$size-m 0;
    margin-bottom: defaults.$size-m;

    h1 {
      font-size: defaults.$font-size-xl;
      margin-bottom: defaults.$size-xs;
    }

    p {
      font-size: defaults.$font-size-m;
    }
  }

  .quick-actions-grid,
  .trending-grid {
    grid-template-columns: 1fr;
    gap: defaults.$size-s;
  }

  .quick-action-card {
    padding: defaults.$size-xs;
  }

  .trending-card-content {
    padding: defaults.$size-s;

    h3 {
      font-size: defaults.$font-size-l;
    }
  }
}
