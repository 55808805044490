@use "../base/defaults";

.submissions-container {
  padding: defaults.$size-s;
  min-width: 90%;
  display: flex;
  background-color: white;
  border-radius: 5px;
  flex-direction: row;
  justify-content: space-between;
}

.submission {
  flex: 1;
  padding: defaults.$size-2xs defaults.$size-xs;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  p {
    flex: 1;
    margin: 0;
    text-wrap: nowrap;
  }
}

.submission--alt {
  @extend .submission;
  background-color: defaults.$light-grey;
}

.submission--header {
  @extend .submission;
  font-weight: bold;
}

.submission-column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.submission-column--name {
  @extend .submission-column;
  text-align: left;
}

.submission-column--email {
  @extend .submission-column;
  text-align: center;
}

.submission-column--score {
  @extend .submission-column;
  text-align: right;
}
