@use "../base/defaults";

.modal-container {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(206, 206, 206, 0.658);
  z-index: 10000000;
}

.modal-content {
  width: auto;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
}

.modal-content__exit {
  padding: defaults.$size-m;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  align-self: flex-end;
  font-size: defaults.$font-size-xl;
}

@media only screen and (max-width: defaults.$breakpoint-lg) {
  // .modal-content {
  //   width: 75%;
  // }
}
