@use '../base/defaults';

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: defaults.$size-l;
  padding: defaults.$size-s;
  border-radius: 16px;
  background: white;
}

.form__control {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: defaults.$size-l;
}

.input-group {
  width: 75%;
  position: relative;
  margin: defaults.$size-m 0;

  input[type='text'] {
    width: 100%;
    padding: defaults.$size-m;
    border: 1px solid rgba(defaults.$colour-gyaan, 0.2);
    border-radius: 4px;
    font-size: defaults.$font-size-xl;
    transition: all 0.2s ease;
    background: white;

    &:focus {
      outline: none;
      border-color: defaults.$colour-gyaan;
    }

    &:focus ~ label,
    &:not(:placeholder-shown) ~ label {
      transform: translateY(-38px) scale(0.85);
      color: defaults.$colour-gyaan;
      background: white;
      padding: 0 defaults.$size-xs;
    }
  }

  label {
    position: absolute;
    left: defaults.$size-m;
    top: defaults.$size-l;
    transform: translateY(-50%);
    color: rgba(defaults.$colour-gyaan, 0.6);
    pointer-events: none;
    transition: all 0.2s ease;
    transform-origin: left top;
    font-size: defaults.$font-size-l;
  }

  .character-count {
    position: absolute;
    right: 0;
    top: -24px;
    font-size: defaults.$font-size-l;
    color: rgba(defaults.$colour-gyaan, 0.8);
    font-weight: 500;
  }
}

.range-group {
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: defaults.$size-s;

  input[type='range'] {
    width: 100%;
    accent-color: defaults.$colour-gyaan;

    // &::-webkit-slider-runnable-track {
    // }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s ease;
      box-shadow: 0 0 0 4px rgba(defaults.$colour-gyaan, 0.1);

      &:hover {
        box-shadow: 0 0 0 6px rgba(defaults.$colour-gyaan, 0.15);
      }

      &:active {
        box-shadow: 0 0 0 8px rgba(defaults.$colour-gyaan, 0.2);
      }
    }

    &:focus {
      outline: none;
    }
  }

  .duration-label {
    font-size: defaults.$font-size-l;
    color: rgba(defaults.$colour-gyaan, 0.8);
    font-weight: 500;
  }
}

.form-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: defaults.$size-s;
  padding-top: defaults.$size-m;
  border-top: 1px solid rgba(defaults.$colour-gyaan, 0.1);
}

@media only screen and (max-width: defaults.$breakpoint-lg) {
  .form-actions {
    width: 100%;
    flex-direction: column;
  }

  .input-group,
  .range-group {
    width: 100%;
  }
}

@media only screen and (max-width: defaults.$breakpoint-md) {
  .form {
    padding: defaults.$size-m;
    gap: defaults.$size-m;
  }

  .form__control {
    align-items: stretch;
    gap: defaults.$size-m;
  }

  .input-group,
  .range-group {
    max-width: 100%;
    margin: defaults.$size-s 0;
  }

  .form-actions {
    flex-direction: row;
    padding-top: defaults.$size-s;
    gap: defaults.$size-xs;
  }

  .input-group,
  .range-group {
    width: 75%;
  }
}

@media only screen and (max-width: defaults.$breakpoint-sm) {
  .form {
    padding: defaults.$size-s;
    gap: defaults.$size-s;
  }

  .form__control {
    gap: defaults.$size-s;
  }

  .input-group,
  .range-group {
    width: 100%;
  }

  .input-group input[type='text'] {
    padding: defaults.$size-s;
    font-size: defaults.$font-size-l;

    &:focus ~ label,
    &:not(:placeholder-shown) ~ label {
      transform: translateY(-32px) scale(0.85);
      color: defaults.$colour-gyaan;
      background: white;
      padding: 0 defaults.$size-xs;
    }
  }

  .form-actions {
    flex-direction: column;
  }
}
