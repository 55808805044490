@use "../base/defaults";

.word-item__container {
  margin-top: defaults.$size-m;
  background: white;
  padding: defaults.$size-s;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(defaults.$colour-gyaan, 0.08);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 8px 30px rgba(defaults.$colour-gyaan, 0.12);
  }
}

.word-item__content {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: defaults.$size-l;
  align-items: stretch;
}

.word-item__answer--heading {
  width: 25%;
  margin: 0;
  padding: defaults.$size-m;
  font-size: defaults.$font-size-xl;
  font-weight: 600;
  color: defaults.$colour-gyaan;
  // background: rgba($colour-gyaan, 0.05);
  border-bottom: 2px solid rgba(defaults.$colour-gyaan, 0.1);
  // border-radius: 12px;
  overflow-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.word-item__answer {
  width: 25%;
  margin: 0;
  padding: defaults.$size-m;
  font-size: defaults.$font-size-xl;
  // color: $colour-gyaan;
  // background: rgba($colour-gyaan, 0.05);
  // border: 2px solid rgba($colour-gyaan, 0.1);
  // border-radius: 12px;
  overflow-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: normal;
}

.word-item__clue--heading {
  width: 75%;
  margin: 0;
  padding: defaults.$size-m;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: defaults.$colour-gyaan;
  // background: white;
  border-bottom: 2px solid rgba(defaults.$colour-gyaan, 0.1);
  // border-radius: 12px;
  font-weight: 600;
  overflow: hidden;
  font-size: defaults.$font-size-l;
  line-height: 1.5;
}

.word-item__clue {
  width: 75%;
  margin: 0;
  padding: defaults.$size-m;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // color: $colour-gyaan;
  // background: white;
  // border: 2px solid rgba($colour-gyaan, 0.1);
  // border-radius: 12px;
  overflow: hidden;
  font-size: defaults.$font-size-l;
  line-height: 1.5;
}

.word-item__controls {
  padding: defaults.$size-m 0 0;
  margin-top: defaults.$size-m;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: defaults.$size-s;
  border-top: 1px solid rgba(defaults.$colour-gyaan, 0.1);
}

@media only screen and (max-width: defaults.$breakpoint-lg) {
  .word-item__content {
    margin-top: defaults.$size-l;
    flex-direction: column;
    gap: 0;
  }

  .word-item__answer--heading {
    display: none;
  }

  .word-item__clue--heading {
    display: none;
  }

  .word-item__answer {
    width: 100%;
    padding: 0 defaults.$size-xs;
  }

  .word-item__clue {
    width: 100%;
    padding: 0 defaults.$size-xs;
    text-align: center;

    h5 {
      width: 100%;
    }
  }

  .word-item__controls {
    width: 100%;
    flex-direction: column;
  }
}

@media only screen and (max-width: defaults.$breakpoint-md) {
  .word-item__content {
    margin-top: 0;
    flex-direction: row;
    gap: 0;
  }

  .word-item__answer--heading {
    display: block;
    padding: 0 defaults.$size-2xs;
    text-align: center;
  }

  .word-item__clue--heading {
    display: block;
    padding: 0 defaults.$size-2xs;
  }

  .word-item__answer {
    width: 25%;
    padding: 0 defaults.$size-xs;
    h5 {
      text-align: right;
    }
  }

  .word-item__clue {
    width: 75%;
    padding: 0 defaults.$size-xs;
    text-align: left;

    h5 {
      width: 100%;
    }
  }
}

@media only screen and (max-width: defaults.$breakpoint-sm) {
  .word-item__content {
    margin-top: defaults.$size-l;
    flex-direction: column;
    gap: 0;
  }

  .word-item__answer--heading {
    display: none;
  }

  .word-item__clue--heading {
    display: none;
  }

  .word-item__answer {
    width: 100%;
    padding: 0 defaults.$size-xs;
  }

  .word-item__clue {
    width: 100%;
    padding: 0 defaults.$size-xs;
    text-align: center;

    h5 {
      width: 100%;
    }
  }
}
