@use 'sass:color';
@use '../base/defaults';

button {
  margin: 0;
  padding: defaults.$size-s defaults.$size-m;
  display: flex;
  flex-direction: row;
  border: none;
  justify-content: space-evenly;
  align-items: center;
  gap: defaults.$size-xs;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  &:disabled {
    background: defaults.$light-grey;
    border-bottom: 0;
    color: defaults.$grey;
    font-weight: normal;
    outline: none;
    box-shadow: none;

    &:hover {
      transform: none;
      // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      cursor: not-allowed;
    }
  }
}

.link-button {
  @extend button;
  text-decoration: none;
  color: white;
  justify-content: center;
  align-items: center;
  background-color: defaults.$colour-gyaan;
  border-bottom: 5px solid
    color.adjust(defaults.$colour-gyaan, $lightness: -10%);
  &:hover {
    border-bottom: 3px solid
      color.adjust(defaults.$colour-gyaan, $lightness: -10%);
    margin-top: 1px;
    margin-bottom: 1px;
  }
  &:active {
    border-bottom: 0;
    margin-top: 5px;
    margin-bottom: 0px;
  }
}

.btn--primary {
  @extend button;
  color: white;
  background: linear-gradient(
    135deg,
    defaults.$colour-gyaan,
    color.adjust(defaults.$colour-gyaan, $lightness: -15%)
  );
  font-weight: 600;
  letter-spacing: 0.5px;
  box-shadow: 0 4px 15px rgba(defaults.$colour-gyaan, 0.25);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(defaults.$colour-gyaan, 0.3);
  }

  &:disabled {
    box-shadow: none;
  }
}

.btn--secondary {
  @extend button;
  background: transparent;
  color: color.adjust(defaults.$colour-kreeda, $lightness: 5%);
  outline: 1px solid color.adjust(defaults.$colour-kreeda, $lightness: 5%);
  box-shadow: 0 4px 15px rgba(defaults.$colour-kreeda, 0.25);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(defaults.$colour-kreeda, 0.3);
  }

  &:disabled {
    box-shadow: none;
  }
}

.btn--delete {
  @extend button;
  background: transparent;
  color: red;
  outline: 1px solid red;
  box-shadow: 0 4px 15px rgba(red, 0.25);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(red, 0.3);
  }

  &:disabled {
    box-shadow: none;
  }
}

@media only screen and (max-width: defaults.$breakpoint-lg) {
  button {
    width: 100%;
    justify-content: center;
  }
}

@media only screen and (max-width: defaults.$breakpoint-md) {
  button {
    width: auto;
    justify-content: space-evenly;
    min-width: defaults.$size-xl * 1.5;
    padding: defaults.$size-xs defaults.$size-m;
    font-size: defaults.$font-size-l;
  }
}

@media only screen and (max-width: defaults.$breakpoint-sm) {
  button {
    min-width: defaults.$size-xl;
    width: 100%;
    justify-content: center;
    padding: defaults.$size-2xs defaults.$size-s;
    font-size: defaults.$font-size-m;
    line-height: 2;
  }
}
