@use 'sass:color';
@use '../base/defaults';

.activity-list {
  display: flex;
  flex-direction: column;
  gap: defaults.$size-s;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.activity-item {
  background: white;
  border-radius: 12px;
  padding: defaults.$size-m;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  text-decoration: none;
  color: defaults.$grey;

  &:hover {
    transform: translateY(-4px);
    border-color: defaults.$colour-gyaan;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
}

.activity-item-no-hover {
  background: white;
  border-radius: 12px;
  padding: defaults.$size-m;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  text-decoration: none;
  color: defaults.$grey;
}

.activity-container {
  padding: defaults.$size-s;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 12px;
}

.activity-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.activity-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: defaults.$size-s;

  h3 {
    color: defaults.$colour-gyaan;
    margin: 0;
    font-size: defaults.$font-size-xl;
  }
}

.options-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.options-button {
  padding: 0 defaults.$size-m;
  border: none;
  background: none;
  color: defaults.$grey;
  font-size: defaults.$font-size-2xl;
  transition: all 0.3s ease;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: none;

  &:hover {
    color: defaults.$colour-kreeda;
    font-weight: bold;
    box-shadow: none;
  }
}

.options-menu {
  position: absolute;
  text-align: start;
  width: fit-content;
  display: none;
  margin: defaults.$size-2xl 0 0 0;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 1px 2px 0 color.adjust(defaults.$colour-kreeda, $lightness: 50%);
  min-width: 200px;

  button {
    @extend .btn--secondary !optional;
    justify-content: flex-start;
    text-wrap: nowrap;
    outline: none;
    box-shadow: none;
    // padding: $size-s $size-m;
    margin: 0;
    border-radius: 0;
    font-size: defaults.$font-size-l;
    display: flex;
    align-items: center;
    gap: defaults.$size-s;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      // background-color: rgba(defaults.$colour-kreeda, 0.1);
    }

    svg {
      font-size: defaults.$font-size-l;
    }

    &.delete-button {
      border-top: 1px solid #ddd;
      color: #c40f0f;

      &:disabled {
        color: #ff9c9c;
        cursor: not-allowed;
        &:hover {
          background: white;
        }
      }

      &:hover {
        background-color: none;
      }
    }
  }
}

.activity-meta {
  display: flex;
  align-items: stretch;
  color: defaults.$grey;
  font-size: defaults.$font-size-l;
  margin-bottom: defaults.$size-m;
  gap: defaults.$size-2xs;
}

.activity-actions {
  display: flex;
  gap: defaults.$size-s;
  justify-content: flex-end;
}

.publish {
  padding: defaults.$size-s defaults.$size-m;
  font-size: defaults.$font-size-l;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.share {
  @extend .btn--secondary !optional;
  padding: defaults.$size-s defaults.$size-m;
  font-size: defaults.$font-size-l;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.code-container {
  min-width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;

  input {
    padding: defaults.$size-2xs defaults.$size-xs;
    text-decoration: none;
    font-size: defaults.$font-size-3xl;
    min-width: 40vw;
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 3px solid defaults.$colour-gyaan;
    &:focus {
      outline: none;
    }
  }

  button {
    margin: 0;
    background-color: defaults.$colour-gyaan;
    color: white;
    width: defaults.$size-xl * 3;
    font-size: defaults.$font-size-xl;
    // border: 2px solid $colour-gyaan;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &:hover {
      transform: none;
    }
  }
}

@media only screen and (max-width: defaults.$breakpoint-xl) {
  .options-menu {
    margin-top: 4vh;
    right: defaults.$size-3xs;
  }
}

@media only screen and (max-width: defaults.$breakpoint-lg) {
  .activity-actions {
    flex-direction: column;
    gap: defaults.$size-s;
  }

  .publish,
  .share {
    justify-content: center;
  }

  .code-container {
    min-width: 750px;

    input {
      font-size: defaults.$font-size-2xl;
    }

    button {
      width: defaults.$size-xl * 3;
      font-size: defaults.$font-size-l;
    }
  }
}

@media only screen and (max-width: defaults.$breakpoint-md) {
  .activity-actions {
    flex-direction: row;
    gap: defaults.$size-s;
  }

  .publish,
  .share {
    justify-content: space-between;
  }

  .code-container {
    min-width: 550px;

    input {
      font-size: defaults.$font-size-xl;
    }

    button {
      width: defaults.$size-xl * 2;
      font-size: defaults.$font-size-m;
    }
  }
}

@media only screen and (max-width: defaults.$breakpoint-sm) {
  .activity-actions {
    flex-direction: column;
    gap: defaults.$size-s;
  }

  .publish,
  .share {
    justify-content: center;
  }

  .code-container {
    min-width: 300px;

    input {
      font-size: defaults.$font-size-l;
    }

    button {
      width: defaults.$size-xl * 2;
      font-size: defaults.$font-size-s;
    }
  }
}
