@use 'sass:color';
@use '../base/defaults';

.card-item {
  flex-grow: 1;
  margin: defaults.$size-s defaults.$size-m;
  padding: defaults.$size-s;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 5px;
  text-decoration: none;
  box-shadow: 0px 1px 2px 0px
    color.adjust(defaults.$colour-gyaan, $lightness: 50%);
  transition: all 0.3s ease;
  &:visited {
    color: black;
  }
  &:hover {
    box-shadow: 0px 2px 2px 1px
      color.adjust(defaults.$colour-gyaan, $lightness: 50%);
    transform: translateY(-3px);
  }
}

.card-item__head {
  padding: 0px defaults.$size-s;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  color: defaults.$colour-gyaan;
}

.card-item__head__heading {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.card-item__head__heading p {
  font-style: italic;
  opacity: 0.3;
}

.card-item__head__options__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.card-item__actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.card-item__share {
  &:visited ~ .modal-container {
    display: flex;
  }
}

.card-item__options {
  padding: 0 defaults.$size-m;
  color: defaults.$grey;
  font-size: defaults.$font-size-3xl;
  transition: all 0.3s ease;
  border-radius: 5px;
  &:hover {
    // background-color: lighten($colour-gyaan, 15);
    // opacity: 0.66;
    cursor: pointer;
    color: defaults.$colour-gyaan;
    font-weight: bold;
  }
}

.card-item__options__menu {
  text-align: start;
  width: fit-content;
  display: none;
  position: absolute;
  margin: defaults.$size-2xl 0 0 0;
  background-color: #fff;
  background-attachment: scroll;
  border: 1px solid #ddd;
  box-shadow: 0px 1px 2px 0px
    color.adjust(defaults.$colour-gyaan, $lightness: 50%);
}

.card-item__options__menu button {
  justify-content: flex-start;
  text-wrap: nowrap;
  padding: defaults.$size-s defaults.$size-m;
  margin: 0;
  border-radius: 0;
  background-color: white;
  color: defaults.$colour-gyaan;
  &:hover {
    background-color: defaults.$colour-gyaan;
    color: white;
  }
  &:disabled {
    color: defaults.$grey;
    &:hover {
      background-color: white;
    }
  }
}

.show {
  display: inline-flex;
  flex-direction: column;
}

@media only screen and (max-width: defaults.$breakpoint-md) {
  .card-item {
    align-items: center;
    justify-content: space-between;
  }

  .card-item__container {
    flex-direction: row;
  }

  .card-item__head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .card-item__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }

  .card-item__options__menu {
    margin-top: 5vh;
    right: 30px;
  }
}
