@use 'sass:color';
@use '../base/defaults';

.header-container {
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  height: fit-content;
  max-width: 100vw;
  position: sticky;
  top: 0;
  z-index: 100;
}

.header {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.mobile-nav {
  min-width: defaults.$size-l * 10;
  padding: 0 defaults.$size-xs;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.menu-container {
  padding: 0 defaults.$size-xs;
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: defaults.$colour-gyaan;
}

.menu-toggle {
  display: none;
  &:checked ~ .mobile-nav-pane {
    left: 0px;
  }
}

.menu-button {
  font-size: defaults.$font-size-3xl;
}

.mobile-nav-pane {
  z-index: 10;
  position: fixed;
  top: 0;
  left: -(defaults.$size-l) * 10;
  min-width: defaults.$size-l * 9;
  display: none;
  flex-direction: column;
  height: 100%;
  background-color: white;
  transition: all 0.5s ease;

  button {
    margin: 0;
    margin-top: 2vh;
    padding: 0;
    align-self: flex-end;
    background-color: white;
    color: defaults.$colour-gyaan;
    font-size: defaults.$font-size-2xl;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
}

.root-link {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: defaults.$font-size-l;
}

.logo-gyaan {
  display: block;
  color: defaults.$colour-gyaan;
  font-weight: bold;
  font-size: defaults.$font-size-xl;
}

.logo-kreeda {
  display: block;
  color: defaults.$colour-kreeda;
  font-weight: bold;
  font-size: defaults.$font-size-xl;
}

.logo-g {
  display: none;
  color: defaults.$colour-gyaan;
  font-weight: bold;
  font-size: defaults.$font-size-3xl;
}

.logo-k {
  display: none;
  color: defaults.$colour-kreeda;
  font-weight: bold;
  font-size: defaults.$font-size-3xl;
}

.header-rest {
  flex-grow: 1;
  padding: defaults.$size-s;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  gap: defaults.$size-xs;
}

.search-container {
  flex-grow: 1;
  margin: 0;
  background-color: rgba(defaults.$light-grey, 0.5);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: defaults.$colour-gyaan;
  border-radius: 8px;
  transition: all 0.2s ease;

  &:focus-within {
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  svg {
    margin-left: defaults.$size-s;
    color: defaults.$grey;
  }

  &:hover {
    flex-grow: 1;
  }

  &:hover > input {
    width: 100%;
  }

  .search-results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: defaults.$size-xs;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-height: 300px;
    overflow-y: auto;
    z-index: 1000;

    .search-result-item {
      text-decoration: none;
      padding: defaults.$size-s;
      color: defaults.$colour-kreeda;
      display: flex;
      align-items: center;
      gap: defaults.$size-s;
      transition: all 0.2s ease;

      &:hover {
        background-color: rgba(defaults.$colour-kreeda, 0.1);
      }

      .search-result-icon {
        color: defaults.$grey;
        width: 16px;
      }

      span {
        &.search-result-type {
          margin-left: auto;
          color: defaults.$grey;
          font-size: defaults.$font-size-s;
          text-transform: capitalize;
        }
      }
    }
  }
}

.search {
  display: flex;

  &:focus {
    width: 100%;
  }
}

.web {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mobile {
  display: none;
}

.header__account-section {
  // height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-menu {
  z-index: 9999;
  min-width: 200px;
  display: none;
  position: absolute;
  top: 100%;
  right: defaults.$size-s;
  margin-top: defaults.$size-xs;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  a {
    text-decoration: none;
    padding: defaults.$size-m;
    color: defaults.$colour-kreeda;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: defaults.$size-s;
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    font-size: defaults.$font-size-l;
    font-weight: normal;

    &:hover {
      background-color: rgba(defaults.$colour-kreeda, 0.1);
    }
  }

  button {
    margin: 0;
    text-decoration: none;
    padding: defaults.$size-m;
    color: defaults.$colour-kreeda;
    transition: all 0.2s ease;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: defaults.$size-s;
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    font-size: defaults.$font-size-l;
    font-weight: normal;
    border-top: 1px solid rgba(0, 0, 0, 0.05);

    &:hover {
      background-color: rgba(defaults.$colour-kreeda, 0.1);
    }
  }
}

.show {
  display: inline-flex;
  flex-direction: column;
}

@media only screen and (max-width: defaults.$breakpoint-lg) {
  .mobile-nav-pane {
    display: flex;
  }

  // .search {
  //   display: flex;
  //   width: auto;
  // }

  .web {
    width: auto;
  }

  // .mobile {
  //   width: auto;
  //   display: flex;
  // }
}

@media only screen and (max-width: defaults.$breakpoint-md) {
  .mobile-nav {
    min-width: fit-content;
  }

  .menu-container {
    display: flex;
  }

  .logo-gyaan,
  .logo-kreeda {
    display: none;
  }

  .logo-g,
  .logo-k {
    display: block;
  }

  .web {
    // display: none;
    width: auto;
  }

  .search-container {
    flex-grow: 0;
    width: fit-content;
  }

  .search {
    width: 0;
  }
}

@media only screen and (max-width: defaults.$breakpoint-sm) {
  .dropdown-menu {
    position: fixed;
    position: absolute;
    top: 100%;
    right: defaults.$size-s;
    width: 35%;
    margin: 0;
  }

  .web {
    display: none;
  }

  .mobile {
    width: auto;
    display: flex;
    font-size: defaults.$font-size-xl;
  }
}
