@use "sass:color";
// colours

$colour-gyaan: #7400a0;
$colour-kreeda: #007f00;
$dark-grey: #333333;
$bright-blue: #1c88df;
$dark-blue: #364051;
$off-white: #f7f7f7;
$grey: #666;
$light-grey: #ececec;
$shadow-grey: color.adjust($light-grey, $lightness: -15%);
$add-button: #2196f3;
$edit-button: #ffa726;
$delete-button: #f44336;

// sizes
$size-3xs: 0.5rem;
$size-2xs: 0.6rem;
$size-xs: 1rem;
$size-s: 1.2rem;
$size-m: 1.6rem;
$size-l: 2.4rem;
$size-xl: 3.2rem;
$size-2xl: 4.8rem;
$size-3xl: 6.4rem;

// Breakpoints
$breakpoint-sm: 36rem; // 576px
$breakpoint-md: 48rem; // 768px
$breakpoint-lg: 62rem; // 992px
$breakpoint-xl: 75rem; // 1200px

// font sizes
$font-size-xs: 0.6rem;
$font-size-s: 0.8rem;
$font-size-m: 1rem;
$font-size-l: 1.2rem;
$font-size-xl: 1.6rem;
$font-size-2xl: 2.4rem;
$font-size-3xl: 3.2rem;
