@use "../base/defaults";

.word-form__container {
  margin-top: defaults.$size-m;
  flex-grow: 1;
  background: white;
  padding: defaults.$size-l;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: defaults.$size-l;
  box-shadow: 0 4px 20px rgba(defaults.$colour-gyaan, 0.08);
  transition: all 0.3s ease;

  @media only screen and (max-width: defaults.$breakpoint-md) {
    padding: defaults.$size-m;
    gap: defaults.$size-m;
  }

  @media only screen and (max-width: defaults.$breakpoint-sm) {
    padding: defaults.$size-s;
    gap: defaults.$size-s;
  }
}

.word-form__textarea {
  width: 100%;
  min-height: 150px;
  padding: defaults.$size-m;
  font-size: defaults.$font-size-xl;
  border: 2px solid rgba(defaults.$colour-gyaan, 0.15);
  border-radius: 12px;
  resize: vertical;
  transition: all 0.2s ease;
  line-height: 1.5;

  &:focus {
    outline: none;
    border-color: defaults.$colour-gyaan;
    box-shadow: 0 2px 8px rgba(defaults.$colour-gyaan, 0.1);
  }

  &::placeholder {
    color: rgba(defaults.$colour-gyaan, 0.4);
  }

  @media only screen and (max-width: defaults.$breakpoint-lg) {
    min-height: 120px;
    padding: defaults.$size-s;
    font-size: defaults.$font-size-l;
  }

  @media only screen and (max-width: defaults.$breakpoint-md) {
    min-height: 100px;
    padding: defaults.$size-xs;
    font-size: defaults.$font-size-m;
  }

  @media only screen and (max-width: defaults.$breakpoint-sm) {
    min-height: 80px;
    padding: defaults.$size-2xs;
    font-size: defaults.$font-size-s;
  }
}

.word-form__controls {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: defaults.$size-s;
  padding-top: defaults.$size-m;
  border-top: 1px solid rgba(defaults.$colour-gyaan, 0.1);

  @media only screen and (max-width: defaults.$breakpoint-lg) {
    flex-direction: column;
    gap: defaults.$size-s;
  }

  @media only screen and (max-width: defaults.$breakpoint-md) {
    padding-top: defaults.$size-s;
    gap: defaults.$size-xs;
  }

  @media only screen and (max-width: defaults.$breakpoint-sm) {
    flex-direction: column;
    gap: defaults.$size-xs;
  }
}
