@use '../base/defaults';

input {
  border: none;
  width: 100%;
  margin: 0;
  padding: defaults.$size-s defaults.$size-xs;
  height: 100%;
  background-color: transparent;
  text-align: left;
  font-size: defaults.$font-size-l;

  &::placeholder {
    color: defaults.$grey;
  }

  &:focus {
    outline: none;
  }
}
