@use 'sass:color';
@use 'defaults';

html {
  font-size: 75%;
}

body {
  margin: 0;
  padding: 0;
  background: defaults.$light-grey;
  color: defaults.$grey;
  font-family: Helvetica, Arial, sans-serif;
  font-size: defaults.$size-m;
  line-height: 1.6;
  height: 100vh;
}

.root-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  overflow-y: auto;
}

.app-container {
  padding: defaults.$size-m defaults.$size-l;
  flex-grow: 1;
  background-color: defaults.$light-grey;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.is-active {
  font-weight: bold;
}

.loader {
  position: fixed;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader img {
  height: defaults.$size-xl;
  width: defaults.$size-xl;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

hr {
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: defaults.$size-2xs;
  height: defaults.$size-2xs;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: color.adjust(defaults.$colour-gyaan, $lightness: 10%);
  border-radius: defaults.$size-3xs;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: color.adjust(defaults.$colour-gyaan, $lightness: -10%);
}

h2 {
  margin: 0;
  // margin-top: $size-m;
  font-size: defaults.$font-size-3xl;
}

h3 {
  margin: 0;
  // margin-top: $size-s;
  font-size: defaults.$font-size-2xl;
}

h4 {
  margin: 0;
  // margin-top: $size-xs;
  font-size: defaults.$font-size-xl;
}

h5 {
  margin: 0;
  // margin-top: $size-2xs;
  font-size: defaults.$font-size-l;
}

h6 {
  margin: 0;
  // margin-top: $size-3xs;
  font-size: defaults.$font-size-m;
}

p {
  margin: 0;
  margin-top: defaults.$size-3xs;
}

.character-limit {
  margin: 0 defaults.$size-2xs;
  color: defaults.$shadow-grey;
}

.validation-message {
  color: red;
  font-style: italic;
  font-size: defaults.$font-size-l;
}

.centered {
  flex: 1;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: defaults.$breakpoint-lg) {
  // .main-container {
  //   flex-direction: column;
  // }

  .app-container {
    padding: defaults.$size-m;
  }
}

@media only screen and (max-width: defaults.$breakpoint-md) {
  html {
    font-size: 70%;
  }

  h2 {
    font-size: defaults.$font-size-2xl;
  }

  h3 {
    font-size: defaults.$font-size-xl;
  }

  h4 {
    font-size: defaults.$font-size-l;
  }

  h5 {
    font-size: defaults.$font-size-m;
  }

  h6 {
    font-size: defaults.$font-size-s;
  }

  .app-container {
    padding: defaults.$size-s;
  }
}

@media only screen and (max-width: defaults.$breakpoint-sm) {
  html {
    font-size: 65%;
  }

  .app-container {
    padding: defaults.$size-xs;
  }

  p {
    margin: defaults.$size-3xs 0;
  }

  ::-webkit-scrollbar {
    width: defaults.$size-3xs;
    height: defaults.$size-3xs;
  }
}
