@use "../base/defaults";

select {
  flex-grow: 1;
  border: none;
  border-radius: 5px;
  background: defaults.$light-grey;
  padding: defaults.$size-xs defaults.$size-xs;
  color: defaults.$colour-gyaan;
  &:focus {
    outline: 1px solid defaults.$colour-gyaan;
  }
}
