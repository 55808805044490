@use "../base/defaults";

.create-container {
  width: 900px;
  color: defaults.$colour-gyaan;
  background: white;
  border-radius: 16px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(10px);
}

.create-content {
  width: 900px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
}

.create-step {
  padding: defaults.$size-xl;
  min-width: 900px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: defaults.$size-m;
  text-align: center;
  transition: all 0.3s ease;
}

.create-navigation {
  padding: defaults.$size-m defaults.$size-xl;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(defaults.$colour-gyaan, 0.1);
  margin-top: defaults.$size-l;
}

.type-items {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  gap: defaults.$size-l;
  width: 100%;
  max-width: 800px;
  margin: defaults.$size-l 0;
}

.type-item {
  padding: defaults.$size-l;
  width: 50%;
  border-radius: 12px;
  background: white;
  box-shadow: 0 4px 20px rgba(defaults.$colour-gyaan, 0.08);
  transition: all 0.3s ease;
  border: 2px solid transparent;

  &:hover {
    cursor: pointer;
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(defaults.$colour-gyaan, 0.15);
    border-color: defaults.$colour-gyaan;
  }

  &:focus {
    border-color: defaults.$colour-gyaan;
    background: rgba(defaults.$colour-gyaan, 0.02);
  }
}

.type-item__image img {
  max-width: 100%;
}

@media only screen and (max-width: defaults.$breakpoint-lg) {
  .create-container {
    width: 700px;
  }
  .create-content {
    width: 700px;
  }

  .create-step {
    min-width: 700px;
  }
}

@media only screen and (max-width: defaults.$breakpoint-md) {
  .create-container {
    width: 600px;
  }
  .create-content {
    width: 600px;
  }

  .create-step {
    min-width: 600px;
  }
}

@media only screen and (max-width: defaults.$breakpoint-sm) {
  .create-container {
    width: 400px;
  }
  .create-content {
    width: 400px;
  }

  .create-step {
    min-width: 400px;
  }
}
