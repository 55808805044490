@use 'sass:color';
@use '../base/defaults';

.question-item__container {
  background: white;
  padding: defaults.$size-l;
  border-radius: 16px;
  margin-top: defaults.$size-l;
  box-shadow: 0 4px 20px rgba(defaults.$colour-gyaan, 0.08);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 8px 30px rgba(defaults.$colour-gyaan, 0.12);
  }
}

.question-item__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: defaults.$size-l;
}

.question-item__text {
  margin: 0;
  padding: defaults.$size-m defaults.$size-l;
  font-size: defaults.$font-size-xl;
  width: 100%;
  // background: linear-gradient(
  //   135deg,
  //   lighten($colour-gyaan, 45%),
  //   lighten($colour-gyaan, 35%)
  // );
  color: defaults.$colour-gyaan;
  border-radius: 12px;
  border: 2px solid rgba(defaults.$colour-gyaan, 0.1);
  overflow-wrap: break-word;
  box-shadow: 0 2px 8px rgba(defaults.$colour-gyaan, 0.08);
}

.question-item__options {
  width: 100%;
  padding-top: defaults.$size-m;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: defaults.$size-m;
}

.question-item__option {
  width: 100%;
  margin: 0;
  padding: defaults.$size-m;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // background: white;
  color: defaults.$colour-gyaan;
  border: 2px solid rgba(defaults.$colour-gyaan, 0.15);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(defaults.$colour-gyaan, 0.05);
    border-color: rgba(defaults.$colour-gyaan, 0.3);
    transform: translateY(-2px);
  }

  &.selected {
    background: defaults.$colour-gyaan;
    color: white;
    border-color: defaults.$colour-gyaan;
  }
}

.question-item__option h5 {
  hyphens: auto;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0;
  font-size: defaults.$font-size-l;
  font-weight: 500;
}

.question-item__answer {
  color: color.adjust(defaults.$colour-gyaan, $lightness: -10%);
  margin: 0;
  display: flex;
  padding: defaults.$size-s defaults.$size-m;
  align-items: center;
  gap: defaults.$size-xs;
}

.question-item__answer p {
  margin: 0;
  border-radius: 4px;
  font-size: defaults.$font-size-l;
  font-weight: 600;
}

.question-item__controls {
  padding: defaults.$size-m 0 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: defaults.$size-s;
  border-top: 1px solid rgba(defaults.$colour-gyaan, 0.1);
  margin-top: defaults.$size-m;
}

@media only screen and (max-width: defaults.$breakpoint-lg) {
  .question-item__container {
    padding: defaults.$size-l;
  }

  .question-item__options {
    display: flex;
    flex-direction: column;
    gap: defaults.$size-xs;
  }

  .question-item__option {
    padding: defaults.$size-xs;
  }

  .question-item__controls {
    flex-direction: column;
    gap: defaults.$size-xs;
  }
}

@media only screen and (max-width: defaults.$breakpoint-md) {
  .question-item__container {
    padding: defaults.$size-m;
  }

  .question-item__text {
    font-size: defaults.$font-size-xl;
    padding: defaults.$size-s;
  }

  .question-item__options {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }

  .question-item__option {
    padding: defaults.$size-s;
    font-size: defaults.$font-size-l;
  }

  .question-item__controls {
    flex-direction: row;
    gap: defaults.$size-xs;
  }
}

@media only screen and (max-width: defaults.$breakpoint-sm) {
  .question-item__container {
    padding: defaults.$size-s;
  }

  .question-item__options {
    display: flex;
    flex-direction: column;
    gap: defaults.$size-xs;
  }

  .question-item__option {
    padding: defaults.$size-xs;
  }

  .question-item__controls {
    flex-direction: column;
    gap: defaults.$size-xs;
  }
}
