@use "../base/defaults";

.question-form__container {
  flex-grow: 1;
  background: white;
  padding: defaults.$size-l;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(defaults.$colour-gyaan, 0.08);
  transition: all 0.3s ease;
}

#create {
  display: none;
}

.question-form__content {
  display: flex;
  flex-direction: column;
  gap: defaults.$size-l;
}

.question-form__text {
  width: 100%;
  padding: defaults.$size-m 0;
  display: flex;
  flex-direction: column;
  gap: defaults.$size-s;
}

.question-form__text-area {
  width: 100%;
  flex-grow: 1;
  font-size: defaults.$font-size-xl;
  padding: defaults.$size-m;
  border: 2px solid rgba(defaults.$colour-gyaan, 0.15);
  border-radius: 12px;
  transition: all 0.2s ease;
  resize: vertical;
  min-height: 100px;

  &:focus {
    outline: none;
    border-color: defaults.$colour-gyaan;
    box-shadow: 0 2px 8px rgba(defaults.$colour-gyaan, 0.1);
  }
}

.question-form__options {
  width: 100%;
  flex-grow: 1;
  padding: defaults.$size-m;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: defaults.$size-m;
}

.question-form__option {
  width: 100%;
  margin: 0;
  padding: defaults.$size-s;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: defaults.$size-m;
  border-radius: 12px;
  color: defaults.$colour-gyaan;
  background: white;
  border: 2px solid rgba(defaults.$colour-gyaan, 0.15);
  transition: all 0.2s ease;

  &:hover {
    border-color: rgba(defaults.$colour-gyaan, 0.3);
    background: rgba(defaults.$colour-gyaan, 0.02);
  }

  &:focus-within {
    border-color: defaults.$colour-gyaan;
    box-shadow: 0 2px 8px rgba(defaults.$colour-gyaan, 0.1);
  }
}

.question-form__option input {
  width: 100%;
  flex-grow: 1;
  font-size: defaults.$font-size-xl;
  padding: defaults.$size-xs;
  border: none;
  background: transparent;
  color: inherit;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: rgba(defaults.$colour-gyaan, 0.4);
  }
}

.faQuestionOption {
  margin: 0 defaults.$size-xs;
  color: rgba(defaults.$colour-gyaan, 0.6);
  transition: all 0.2s ease;

  &:hover {
    cursor: pointer;
    color: defaults.$colour-gyaan;
    transform: scale(1.2);
  }
}

.question-form__answer {
  margin-top: defaults.$size-m;
  justify-self: center;
  font-size: defaults.$font-size-l;
  min-width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: defaults.$size-m;
  padding: defaults.$size-m;
  border-radius: 12px;
  background: rgba(defaults.$colour-gyaan, 0.05);
}

.question-form__answer p {
  margin: 0;
  color: defaults.$colour-gyaan;
  font-weight: 500;
}

.question-form__controls {
  width: 100%;
  align-self: flex-end;
  padding-top: defaults.$size-m;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: defaults.$size-s;
  border-top: 1px solid rgba(defaults.$colour-gyaan, 0.1);
}

@keyframes dropdown {
  from {
    height: 0px;
  }
  to {
    height: 100px;
  }
}

@media only screen and (max-width: defaults.$breakpoint-lg) {
  .question-form__options {
    display: flex;
    flex-direction: column;
    gap: defaults.$size-xs;
  }

  .question-form__controls {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: defaults.$breakpoint-md) {
  .question-form__container {
    padding: defaults.$size-m;
  }

  .question-form__text-area {
    font-size: defaults.$font-size-l;
    min-height: 80px;
  }

  .question-form__options {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }

  .question-form__option {
    font-size: defaults.$font-size-l;
    padding: defaults.$size-s;
  }

  .question-form__option input {
    font-size: defaults.$font-size-l;
  }

  .question-form__controls {
    flex-direction: row;
    gap: defaults.$size-xs;
  }
}

@media only screen and (max-width: defaults.$breakpoint-sm) {
  .question-form__container {
    padding: defaults.$size-s;
  }

  .question-form__text-area {
    min-height: 60px;
  }

  .question-form__options {
    display: flex;
    flex-direction: column;
    gap: defaults.$size-xs;
  }

  .question-form__option {
    padding: defaults.$size-xs;
    font-size: defaults.$font-size-m;
  }

  .question-form__option input {
    font-size: defaults.$font-size-m;
  }

  .question-form__controls {
    flex-direction: column;
    gap: defaults.$size-xs;
  }
}
