@use "../base/defaults";

textarea {
  width: 100%;
  resize: none;
  font-family: Helvetica, Arial, sans-serif;
  background-color: defaults.$light-grey;
  border-radius: 5px;
  color: defaults.$colour-gyaan;
  padding: defaults.$size-xs;
  border: none;
  &:focus {
    outline: 1px solid defaults.$colour-gyaan;
  }
  &::placeholder {
    color: defaults.$colour-gyaan;
    opacity: 0.5;
    font-style: italic;
  }
}
