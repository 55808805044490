@use '../base/defaults';

.nav-container {
  display: block;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  min-width: defaults.$size-l * 10;
  transition: width 0.3s ease;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.nav-pane {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.nav-pane__link {
  color: defaults.$grey;
  padding: defaults.$size-m defaults.$size-l;
  text-decoration: none;
  font-size: defaults.$font-size-l;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: defaults.$size-s;
  margin: defaults.$size-xs 0;

  &:hover {
    color: defaults.$colour-gyaan;
    padding-left: defaults.$size-xl;
  }
}

.nav-pane__link-active {
  color: defaults.$colour-gyaan;
  font-weight: 500;
  background: rgba(defaults.$colour-gyaan, 0.05);

  &:hover {
    color: defaults.$colour-gyaan;
    padding-left: defaults.$size-xl;
  }
}

// Mobile Navigation
@media only screen and (max-width: defaults.$breakpoint-md) {
  .nav-container {
    display: none;
  }

  .nav-pane {
    display: none;
  }

  .nav-pane__link {
    padding: defaults.$size-s defaults.$size-m defaults.$size-s defaults.$size-s;
    &:hover {
      padding-left: defaults.$size-m;
      padding-right: defaults.$size-s;
    }
  }

  .nav-pane__link-active {
    padding: defaults.$size-s defaults.$size-m defaults.$size-s defaults.$size-s;
  }
}
