@use 'sass:color';
@use '../base/defaults';

.notification-toast__container {
  position: absolute;
  width: 100vw;
  // top: -60px;
  transform: translateY(-60px);
}

.dropdown {
  animation: dropdown 0.5s;
  animation-fill-mode: forwards;
}

.riseup {
  animation: riseup 0.5s;
  animation-fill-mode: backwards;
}

.notification-toast__content {
  margin: 0 auto;
  padding: defaults.$size-3xs defaults.$size-2xs 0 defaults.$size-2xs;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: defaults.$colour-gyaan;
  color: white;
}

.notification-toast__message {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.notification-toast__message button {
  margin: 0;
  line-height: 0;
  background: none;
  color: white;
  font-size: defaults.$font-size-xl;
  font-weight: bold;
  min-width: fit-content;
  max-width: fit-content;
}

.notification-toast__timer {
  margin: 0;
  padding: 0;
  height: 5px;
  background-color: color.adjust(
    $color: defaults.$colour-gyaan,
    $lightness: -15%
  );
  animation: timer 6s;
}

@keyframes dropdown {
  0% {
    // top: -60px;
    transform: translateY(-60px);
    opacity: 0;
  }

  100% {
    // top: 0px;
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes riseup {
  0% {
    // top: 0px;
    transform: translateY(0px);
    opacity: 1;
  }

  100% {
    // top: -60px;
    transform: translateY(-60px);
    opacity: 0;
  }
}

@keyframes timer {
  0% {
    width: 100%;
  }

  100% {
    width: 0px;
  }
}
